import { CHAIN_ID } from "./tokens";

export const PUBLIC_PRICE = 0.05;

export const WHITELIST_PRICE = 0.04;

export const SHOW_MINT = process.env.REACT_APP_SHOW_MINT;
export const ENABLED_CHAIN = process.env.REACT_APP_ENABLED_CHAINS ?
  process.env.REACT_APP_ENABLED_CHAINS.split(",")
  : [
    "ZKSYNC", // 0 index is the main chain
    "ETH", "BSC", "POLYGON", "OPTIMISM", "ARBITRUM_ONE",
    "ZKSYNC_TEST",
    "GOERLI", "BSC_TEST", "ARBITRUM_GOERLI"
  ];

export const MAIN_CHAIN = CHAIN_ID[ENABLED_CHAIN[0]];

export const ENABLED_CHAIN_IDS = ENABLED_CHAIN.map((chain) => CHAIN_ID[chain]);

export const STATIC_LAYER_ZERO_GAS = {
  [CHAIN_ID.BSC]: "",
  [CHAIN_ID.ETH]: "",
  [CHAIN_ID.ARBITRUM_ONE]: 0.005,
  [CHAIN_ID.OPTIMISM]: "",
  [CHAIN_ID.POLYGON]: 15,
  [CHAIN_ID.BSC_TEST]: "",
  [CHAIN_ID.GOERLI]: "",
  [CHAIN_ID.ARBITRUM_GOERLI]: "",
  [CHAIN_ID.ZKSYNC]: "",
  [CHAIN_ID.ZKSYNC_TEST]: ""
};

export const MATIC_GAS_MULTIPLIER = 1.2;
