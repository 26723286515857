import { ethers } from "ethers";
import { chains } from "./chains";
import { ENABLED_CHAIN_IDS } from "./constants";

const providers = {};

ENABLED_CHAIN_IDS.forEach((chainId) => {
  const selectedChain = chains.find((chain) => chain.id === Number(chainId));
  providers[chainId] = new ethers.providers.JsonRpcProvider(
    selectedChain.rpcUrl
  );
});

export { providers };
