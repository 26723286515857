import { ethers } from "ethers";

export const TOKEN_SYMBOL = {
  BNB: "bnb",
  USDT: "usdt",
  USDC: "usdc",
  BUSD: "busd",
  ETH: "eth",
  TBNB: "tbnb",
  WETH: "weth",
  MATIC: "matic",
  DAI: "dai",
  HAY: "hay"
};

export const CHAIN_ID = {
  BSC: "0x38",
  ETH: "0x1",
  ARBITRUM_ONE: "0xa4b1",
  OPTIMISM: "0xa",
  POLYGON: "0x89",
  ZKSYNC: "0x144",
  BSC_TEST: "0x61",
  GOERLI: "0x5",
  ARBITRUM_GOERLI: "0x66eed",
  ZKSYNC_TEST: "0x118"
};

export const contracts = {
  // Main chain NFT contracts
  nftContract: {
    [CHAIN_ID.BSC]: "",
    [CHAIN_ID.ETH]: "",
    [CHAIN_ID.ARBITRUM_ONE]: "",
    [CHAIN_ID.OPTIMISM]: "",
    [CHAIN_ID.POLYGON]: "",
    [CHAIN_ID.BSC_TEST]: "",
    [CHAIN_ID.GOERLI]: "",
    [CHAIN_ID.ARBITRUM_GOERLI]: "",
    [CHAIN_ID.ZKSYNC]: "0x92691e540Ac5314f5E12b8aF476c3EDeEEbd6B58",
    [CHAIN_ID.ZKSYNC_TEST]: "0xDAAC0E3fE3380805276F2756eEd6290E792Eea85"
  },
  mintFullFill: {
    [CHAIN_ID.BSC]: "",
    [CHAIN_ID.ETH]: "",
    [CHAIN_ID.ARBITRUM_ONE]: "",
    [CHAIN_ID.OPTIMISM]: "",
    [CHAIN_ID.POLYGON]: "",
    [CHAIN_ID.BSC_TEST]: "",
    [CHAIN_ID.GOERLI]: "",
    [CHAIN_ID.ARBITRUM_GOERLI]: "",
    [CHAIN_ID.ZKSYNC]: "0x8f09296C8c50d4C0CfbA6268ab387D48989FD1b1",
    [CHAIN_ID.ZKSYNC_TEST]: "0x4261dcd7DF71e53241f0783B337DF6CF72ED6544"
  },
  // Crosschain Clip NFT contracts
  layerZero: {
    [CHAIN_ID.BSC]: "0x3c2269811836af69497E5F486A85D7316753cf62",
    [CHAIN_ID.ETH]: "0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675",
    [CHAIN_ID.ARBITRUM_ONE]: "0x3c2269811836af69497E5F486A85D7316753cf62",
    [CHAIN_ID.OPTIMISM]: "0x3c2269811836af69497E5F486A85D7316753cf62",
    [CHAIN_ID.POLYGON]: "0x3c2269811836af69497E5F486A85D7316753cf62",
    [CHAIN_ID.BSC_TEST]: "0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1",
    [CHAIN_ID.GOERLI]: "0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23",
    [CHAIN_ID.ARBITRUM_GOERLI]: "0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab",
    [CHAIN_ID.ZKSYNC]: "0x9b896c0e23220469C7AE69cb4BbAE391eAa4C8da",
    [CHAIN_ID.ZKSYNC_TEST]: "0x093D2CF57f764f09C3c2Ac58a42A2601B8C79281"
  },
  mintDeposit: {
    [CHAIN_ID.ETH]: "0xc3d9619463379dfd252309ef75d27d2ba96f388c",
    [CHAIN_ID.BSC]: "0x3cE610a65b4Ef3Ee330dB963DD1588f03f2e3aab",
    [CHAIN_ID.ARBITRUM_ONE]: "0x7dC43Fb4FB465141A931968A751E22F53a498E5d",
    [CHAIN_ID.OPTIMISM]: "0xd5Afa51059CdA856bD6631Bba99083099f4a9c87",
    [CHAIN_ID.POLYGON]: "0x3cE610a65b4Ef3Ee330dB963DD1588f03f2e3aab",
    [CHAIN_ID.BSC_TEST]: "0x6882E48e2dfA6241C548c3Ee4B6054F09545E5e8",
    [CHAIN_ID.GOERLI]: "0x000866dD09Fcd0c3E76A64686859aaC632c67C94",
    [CHAIN_ID.ARBITRUM_GOERLI]: "0x1f54F1c96AE79C8902aE5519a09191517c561EEb"
  },
  wNative: {
    // required for crosschain minting
    [CHAIN_ID.BSC]: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    [CHAIN_ID.ETH]: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    [CHAIN_ID.ARBITRUM_ONE]: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    [CHAIN_ID.OPTIMISM]: "0x4200000000000000000000000000000000000006",
    [CHAIN_ID.POLYGON]: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    [CHAIN_ID.BSC_TEST]: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    [CHAIN_ID.GOERLI]: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    [CHAIN_ID.ARBITRUM_GOERLI]: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
    [CHAIN_ID.ZKSYNC]: "",
    [CHAIN_ID.ZKSYNC_TEST]: ""
  },
  wEth: {
    // required for crosschain minting // can be erc20 token or wNative depending on the chain
    [CHAIN_ID.BSC]: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    [CHAIN_ID.ETH]: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    [CHAIN_ID.ARBITRUM_ONE]: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    [CHAIN_ID.OPTIMISM]: "0x4200000000000000000000000000000000000006",
    [CHAIN_ID.POLYGON]: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    [CHAIN_ID.BSC_TEST]: "0x80ccAd4784d1B33B460da7F5036ce8921BBA2574",
    [CHAIN_ID.GOERLI]: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    [CHAIN_ID.ARBITRUM_GOERLI]: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
    [CHAIN_ID.ZKSYNC]: "",
    [CHAIN_ID.ZKSYNC_TEST]: ""
  },
  uniV3Factory: {
    [CHAIN_ID.BSC]: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
    [CHAIN_ID.ETH]: "",
    [CHAIN_ID.ARBITRUM_ONE]: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    [CHAIN_ID.OPTIMISM]: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    [CHAIN_ID.POLYGON]: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    [CHAIN_ID.BSC_TEST]: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
    [CHAIN_ID.GOERLI]: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    [CHAIN_ID.ARBITRUM_GOERLI]: "0xE1c9A1b8fA38226e1848Cc8a257B95B0676781f3",
    [CHAIN_ID.ZKSYNC]: "",
    [CHAIN_ID.ZKSYNC_TEST]: ""
  }
};

export const NFT_TOKENS = {
  [CHAIN_ID.BSC]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.BNB,
      swapPath: [
        contracts.wNative[CHAIN_ID.BSC],
        2500,
        contracts.wEth[CHAIN_ID.BSC]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [contracts.wNative[CHAIN_ID.BSC], 2500, contracts.wEth[CHAIN_ID.BSC]]
      )
    },
    {
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
      symbol: TOKEN_SYMBOL.WETH,
      swapPath: [
        contracts.wNative[CHAIN_ID.BSC],
        2500,
        contracts.wEth[CHAIN_ID.BSC]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [contracts.wNative[CHAIN_ID.BSC], 2500, contracts.wEth[CHAIN_ID.BSC]]
      )
    },
    {
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimals: 18,
      symbol: TOKEN_SYMBOL.USDT, // has only $3000 liquidity with WETH pair
      swapPath: [
        "0x55d398326f99059fF775485246999027B3197955",
        100,
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        500,
        contracts.wEth[CHAIN_ID.BSC]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address", "uint24", "address"],
        [
          "0x55d398326f99059fF775485246999027B3197955",
          100,
          "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
          500,
          contracts.wEth[CHAIN_ID.BSC]
        ]
      ),
      isMultiHoopPath: true
    },
    {
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      decimals: 18,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        500,
        contracts.wEth[CHAIN_ID.BSC]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
          500,
          contracts.wEth[CHAIN_ID.BSC]
        ]
      )
    },
    {
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      decimals: 18,
      symbol: TOKEN_SYMBOL.BUSD, // has not pair with WETH
      swapPath: [
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        100,
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        500,
        contracts.wEth[CHAIN_ID.BSC]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address", "uint24", "address"],
        [
          "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
          100,
          "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
          500,
          contracts.wEth[CHAIN_ID.BSC]
        ]
      ),
      isMultiHoopPath: true
    }
  ],
  [CHAIN_ID.ETH]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    }
  ],
  [CHAIN_ID.ARBITRUM_ONE]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    },
    {
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        500,
        contracts.wEth[CHAIN_ID.ARBITRUM_ONE]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
          500,
          contracts.wEth[CHAIN_ID.ARBITRUM_ONE]
        ]
      )
    },
    {
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDT,
      swapPath: [
        "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        500,
        contracts.wEth[CHAIN_ID.ARBITRUM_ONE]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
          500,
          contracts.wEth[CHAIN_ID.ARBITRUM_ONE]
        ]
      )
    }
  ],
  [CHAIN_ID.OPTIMISM]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    },
    {
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        500,
        contracts.wEth[CHAIN_ID.OPTIMISM]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
          500,
          contracts.wEth[CHAIN_ID.OPTIMISM]
        ]
      )
    },
    {
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDT,
      swapPath: [
        "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
        500,
        contracts.wEth[CHAIN_ID.OPTIMISM]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
          500,
          contracts.wEth[CHAIN_ID.OPTIMISM]
        ]
      )
    }
  ],
  [CHAIN_ID.POLYGON]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.MATIC,
      swapPath: [
        contracts.wNative[CHAIN_ID.POLYGON],
        500,
        contracts.wEth[CHAIN_ID.POLYGON]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          contracts.wNative[CHAIN_ID.POLYGON],
          500,
          contracts.wEth[CHAIN_ID.POLYGON]
        ]
      )
    },
    {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      decimals: 18,
      symbol: TOKEN_SYMBOL.WETH,
      swapPath: [
        contracts.wNative[CHAIN_ID.POLYGON],
        500,
        contracts.wEth[CHAIN_ID.POLYGON]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          contracts.wNative[CHAIN_ID.POLYGON],
          500,
          contracts.wEth[CHAIN_ID.POLYGON]
        ]
      )
    },
    {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        500,
        contracts.wEth[CHAIN_ID.POLYGON]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          500,
          contracts.wEth[CHAIN_ID.POLYGON]
        ]
      )
    },
    {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDT,
      swapPath: [
        "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        100,
        "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        500,
        contracts.wEth[CHAIN_ID.POLYGON]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address", "uint24", "address"],
        [
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          100,
          "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          500,
          contracts.wEth[CHAIN_ID.POLYGON]
        ]
      ),
      isMultiHoopPath: true
    }
  ],
  [CHAIN_ID.BSC_TEST]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.TBNB,
      swapPath: [
        contracts.wNative[CHAIN_ID.BSC_TEST],
        100,
        "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
        500,
        contracts.wEth[CHAIN_ID.BSC_TEST]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address", "uint24", "address"],
        [
          contracts.wNative[CHAIN_ID.BSC_TEST],
          100,
          "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
          500,
          contracts.wEth[CHAIN_ID.BSC_TEST]
        ]
      ),
      isMultiHoopPath: true
    },
    {
      address: "0x80ccAd4784d1B33B460da7F5036ce8921BBA2574",
      decimals: 18,
      symbol: TOKEN_SYMBOL.WETH,
      swapPath: [
        contracts.wNative[CHAIN_ID.BSC_TEST],
        500,
        contracts.wEth[CHAIN_ID.BSC_TEST]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          contracts.wNative[CHAIN_ID.BSC_TEST],
          500,
          contracts.wEth[CHAIN_ID.BSC_TEST]
        ]
      )
    },
    {
      address: "0xCA8eB2dec4Fe3a5abbFDc017dE48E461A936623D",
      decimals: 18,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0xCA8eB2dec4Fe3a5abbFDc017dE48E461A936623D",
        500,
        contracts.wEth[CHAIN_ID.BSC_TEST]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0xCA8eB2dec4Fe3a5abbFDc017dE48E461A936623D",
          500,
          contracts.wEth[CHAIN_ID.BSC_TEST]
        ]
      )
    },
    {
      address: "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
      decimals: 18,
      symbol: TOKEN_SYMBOL.BUSD,
      swapPath: [
        "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
        500,
        contracts.wEth[CHAIN_ID.BSC_TEST]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814",
          500,
          contracts.wEth[CHAIN_ID.BSC_TEST]
        ]
      )
    }
  ],
  [CHAIN_ID.GOERLI]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    },
    {
      address: "0x796cb8349451ec2e1B09Be3645139185B63AFC00",
      decimals: 8,
      symbol: TOKEN_SYMBOL.USDC,
      swapPath: [
        "0x796cb8349451ec2e1B09Be3645139185B63AFC00",
        500,
        contracts.wEth[CHAIN_ID.GOERLI]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        [
          "0x796cb8349451ec2e1B09Be3645139185B63AFC00",
          500,
          contracts.wEth[CHAIN_ID.GOERLI]
        ]
      )
    },
    {
      address: "0xE1c9A1b8fA38226e1848Cc8a257B95B0676781f3",
      decimals: 6,
      symbol: TOKEN_SYMBOL.USDT,
      swapPath: [
        "0xE1c9A1b8fA38226e1848Cc8a257B95B0676781f3",
        3000,
        "0x796cb8349451ec2e1B09Be3645139185B63AFC00",
        500,
        contracts.wEth[CHAIN_ID.GOERLI]
      ],
      swapParams: ethers.utils.solidityPack(
        ["address", "uint24", "address", "uint24", "address"],
        [
          "0xE1c9A1b8fA38226e1848Cc8a257B95B0676781f3",
          3000,
          "0x796cb8349451ec2e1B09Be3645139185B63AFC00",
          500,
          contracts.wEth[CHAIN_ID.GOERLI]
        ]
      ),
      isMultiHoopPath: true
    }
  ],
  [CHAIN_ID.ARBITRUM_GOERLI]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    }
  ],
  [CHAIN_ID.ZKSYNC]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    }
  ],
  [CHAIN_ID.ZKSYNC_TEST]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      symbol: TOKEN_SYMBOL.ETH,
      swapPath: []
    }
  ]
};
