import { parseEther } from "viem";
import { waitForTransaction } from "@wagmi/core";
import {
  getPublicClient,
  getPublicClientHttp,
  getWalletClient
} from "./web3Client";
import { tokenAbi } from "../Abi/tokenAbi";
import { proofs } from "./proofs";
import { zKittyNftAbi } from "../Abi/zKittyNftAbi";
import { mintDepositAbi } from "../Abi/mintDepositAbi";
import { ethers } from "ethers";
import { MAIN_CHAIN, PUBLIC_PRICE, WHITELIST_PRICE } from "./constants";
import { contracts, CHAIN_ID } from "./tokens";
import { zkSync, zkSyncTestnet } from "wagmi/chains";
import { MerkleTree } from "merkletreejs";
import { whitelistAddressList } from "../Utils/address";

import { toast } from "react-toastify";
const hashedWhitelistAddresses = whitelistAddressList.map((address) =>
  ethers.utils.keccak256(address)
);
const merkleTree = new MerkleTree(
  hashedWhitelistAddresses,
  ethers.utils.keccak256,
  {
    sortPairs: true
  }
);

export const mintNFTs = async (chain, number, contractAddress) => {
  try {
    const walletClient = await getWalletClient(chain);
    const [account] = await walletClient.getAddresses();
    const { request } = await getPublicClient(chain).simulateContract({
      account,
      address: contractAddress,
      abi: zKittyNftAbi,
      functionName: "mintNFTs",
      args: [number],
      value: parseEther((number * PUBLIC_PRICE).toString())
    });
    const hash = await walletClient.writeContract(request);
    const data = await waitForTransaction({
      hash
    });
    return data.status === "success";
  } catch (e) {
    console.log(e);
    return null;
  }
};

// export const whitelistMintNFTs = async (chain, number, contractAddress) => {
//   try {
//     const walletClient = await getWalletClient(chain);
//     const [account] = await walletClient.getAddresses();
//     if (!account) return;
//     const hashedAddress = ethers.utils.keccak256(account);
//     const proof = merkleTree.getHexProof(hashedAddress);
//     if (proof?.length > 0) {
//       const { request } = await getPublicClient(chain).simulateContract({
//         account,
//         address: contractAddress,
//         abi: zKittyNftAbi,
//         functionName: "whitelistMint",
//         args: [number, proof],
//         value: parseEther((number * WHITELIST_PRICE).toString())
//       });
//       const hash = await walletClient.writeContract(request);
//       const data = await waitForTransaction({
//         hash
//       });
//       return data.status === "success";
//     }
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// };

export const crossChainMint = async (
  chain,
  number,
  contractAddress,
  transFees,
  amount,
  selectedToken,
  userWhitelisted
) => {
  try {
    const walletClient = await getWalletClient(chain);
    const [account] = await walletClient.getAddresses();

    const isNativeToken =
      selectedToken.address === ethers.constants.AddressZero;

    const reservedForGas = parseEther(transFees.toString());

    const publicSalePrice = amount
      ? amount
      : parseEther((number * PUBLIC_PRICE).toString());

    const whitelistSalePrice = amount
      ? amount
      : parseEther((number * WHITELIST_PRICE).toString());

    const adapterParams = ethers.utils.solidityPack(
      ["uint16", "uint256"],
      [1, 2_000_000 + 600_000 * number]
    );

    let swapParams;

    if (!isNativeToken || selectedToken.symbol.toUpperCase() === "WETH") {
      if (selectedToken.symbol.toUpperCase() === "WETH") {
        swapParams = [
          ethers.utils.solidityPack(["address"], [selectedToken.address]),
          selectedToken.address,
          amount,
          false
        ];
      } else {
        swapParams = [
          selectedToken.swapParams,
          selectedToken.address,
          amount,
          false
        ];
      }
      const allowance = await getAllowance(
        chain,
        selectedToken.address,
        account,
        contractAddress
      );
      if (allowance < amount) {
        const response = await approve(
          chain,
          account,
          selectedToken.address,
          contractAddress,
          amount
        );
        if (!response) {
          return null;
        }
      }
    } else {
      if (!selectedToken.isMultiHoopPath && !selectedToken.swapParams) {
        swapParams = [
          ethers.utils.solidityPack(
            ["address"],
            [ethers.constants.AddressZero]
          ),
          ethers.constants.AddressZero,
          0,
          true
        ];
      } else if (!selectedToken.isMultiHoopPath && selectedToken.swapParams) {
        swapParams = [
          selectedToken.swapParams,
          selectedToken.swapPath[0],
          amount,
          true
        ];
      } else {
        swapParams = [
          selectedToken.swapParams,
          selectedToken.swapPath[0],
          userWhitelisted ? publicSalePrice : whitelistSalePrice,
          true
        ];
      }
    }
    if (!account) return;
    const hashedAddress = ethers.utils.keccak256(account);
    const proof = merkleTree.getHexProof(hashedAddress);

      const { request } = await getPublicClient(chain).simulateContract({
        account,
        address: contractAddress,
        abi: mintDepositAbi,
        functionName: "crossChainPublicMint",
        args: [number, adapterParams, swapParams, reservedForGas],
        value: !isNativeToken
          ? reservedForGas
          : (
              Number(reservedForGas.toString()) +
              Number(publicSalePrice.toString())
            ).toString()
      });
      const hash = await walletClient.writeContract(request);
      const data = await waitForTransaction({
        hash
      });

      return data.status === "success";

  } catch (e) {
    console.log(e);
    if(e.message.includes("insufficient funds")){
      toast.warning("Not enough balance for gas!", {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
    return null;
  }
};

export const getBalance = async (chain, tokenAddress, account) => {
  try {
    const response = await getPublicClient(chain).readContract({
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "balanceOf",
      args: [account]
    });
    return response;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getMintedAmount = async (account) => {
  try {
    const mainChainClient = getPublicClientHttp(
      MAIN_CHAIN === CHAIN_ID.ZKSYNC ? zkSync : zkSyncTestnet
    );
    const response = await mainChainClient.readContract({
      address: contracts.nftContract[MAIN_CHAIN],
      abi: zKittyNftAbi,
      functionName: "mintedAmount",
      args: [account]
    });
    return response;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getTotalSupply = async () => {
  try {
    const mainChainClient = getPublicClientHttp(
      MAIN_CHAIN === CHAIN_ID.ZKSYNC ? zkSync : zkSyncTestnet
    );
    const response = await mainChainClient.readContract({
      address: contracts.nftContract[MAIN_CHAIN],
      abi: zKittyNftAbi,
      functionName: "totalSupply"
    });
    return response;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getAllowance = async (
  chain,
  tokenAddress,
  account,
  contractAddress
) => {
  try {
    const response = await getPublicClient(chain).readContract({
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "allowance",
      args: [account, contractAddress]
    });
    return Number(response.toString());
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const approve = async (
  chain,
  account,
  tokenAddress,
  contractAddress,
  amount
) => {
  try {
    const walletClient = await getWalletClient(chain);
    const { request } = await getPublicClient(chain).simulateContract({
      account,
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "approve",
      args: [contractAddress, amount]
    });
    const hash = await walletClient.writeContract(request);
    if (hash) {
      const data = await waitForTransaction({
        hash
      });
      if (data?.status !== "success") {
        return false;
      }
    } else {
      return false;
    }
    return true;
  } catch (e) {
    console.log(e);
    return null;
  }
};
