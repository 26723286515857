import React from "react";

const Footer = () => {
  return (
    <div className='footer'>
      <span>@ZKitty 2024 All Rights Reserved</span>
    </div>
  );
};

export default Footer;
