import { createPublicClient, createWalletClient, custom, http } from "viem";

export const getPublicClient = (chain) =>
  createPublicClient({
    chain,
    transport: custom(window.ethereum)
  });

export const getPublicClientHttp = (chain) =>
  createPublicClient({
    chain,
    transport: http()
  });

export const getWalletClient = (chain) =>
  createWalletClient({
    chain,
    transport: custom(window.ethereum)
  });
