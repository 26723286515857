const Cats = ({name}) => {
  return (
    <div className={`cats ${name}`}>
      <img
        alt='cats'
        src={`/${name}.png`}
        width={10000}
        height={10000}
        style={{
          objectFit: "contain"
        }}
      />
    </div>
  );
};

export default Cats;
