import { CHAIN_ID } from "./tokens";

export const chains = [
  {
    id: 1,
    chainId: CHAIN_ID.ETH,
    name: "ETH",
    fullName: "Ethereum",
    rpcUrl: "https://rpc.ankr.com/eth",
    icon: "/img/assets/networks/ethereum-logo-tiny.svg",
    explorerUrl: "https://etherscan.io/"
  },
  {
    id: 56,
    chainId: CHAIN_ID.BSC,
    name: "BSC",
    fullName: "Binance Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org",
    icon: "/img/assets/networks/bsc-logo-tiny.svg",
    explorerUrl: "https://bscscan.com/"
  },
  {
    id: 42161,
    chainId: CHAIN_ID.ARBITRUM_ONE,
    name: "ARBITRUM",
    fullName: "Arbitrum One",
    rpcUrl: "https://endpoints.omniatech.io/v1/arbitrum/mainnet/public",
    icon: "/img/assets/networks/arbitrum-logo-tiny.svg",
    explorerUrl: "https://arbiscan.io/"
  },
  {
    id: 10,
    chainId: CHAIN_ID.OPTIMISM,
    name: "OPTIMISM",
    fullName: "Optimism",
    rpcUrl: "https://endpoints.omniatech.io/v1/op/mainnet/public",
    icon: "/img/assets/networks/optimism-logo-tiny.svg",
    explorerUrl: "https://optimistic.etherscan.io/"
  },
  {
    id: 137,
    chainId: CHAIN_ID.POLYGON,
    name: "POLYGON",
    fullName: "Polygon",
    rpcUrl: "https://polygon-bor.publicnode.com",
    icon: "/img/assets/networks/polygon-logo-tiny.svg",
    explorerUrl: "https://polygonscan.com/"
  },
  {
    id: 97,
    chainId: CHAIN_ID.BSC_TEST,
    name: "BSC Test",
    fullName: "Binance Smart Chain TEST",
    rpcUrl: "https://endpoints.omniatech.io/v1/bsc/testnet/public",
    icon: "/img/assets/networks/bsc-logo-tiny.svg",
    explorerUrl: "https://testnet.bscscan.com/"
  },
  {
    id: 5,
    chainId: CHAIN_ID.GOERLI,
    name: "GoerliETH",
    fullName: "Goerli",
    rpcUrl: "https://ethereum-goerli.publicnode.com",
    icon: "/img/assets/networks/ethereum-logo-tiny.svg",
    explorerUrl: "https://goerli.etherscan.io/"
  },
  {
    id: 421613,
    chainId: CHAIN_ID.ARBITRUM_GOERLI,
    name: "AGOR",
    fullName: "Arbitrum Goerli",
    rpcUrl: "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
    icon: "/img/assets/networks/ethereum-logo-tiny.svg",
    explorerUrl: "https://goerli.arbiscan.io/"
  },
  {
    id: 324,
    chainId: CHAIN_ID.ZKSYNC,
    name: "zkSync",
    fullName: "zkSync Era",
    rpcUrl: "https://mainnet.era.zksync.io",
    icon: "/img/assets/networks/ethereum-logo-tiny.svg",
    explorerUrl: "https://explorer.zksync.io"
  },
  {
    id: 280,
    chainId: CHAIN_ID.ZKSYNC_TEST,
    name: "zkSync",
    fullName: "zkSync Era Testnet",
    rpcUrl: "https://testnet.era.zksync.dev",
    icon: "/img/assets/networks/ethereum-logo-tiny.svg",
    explorerUrl: "https://goerli.explorer.zksync.io"
  }
];
