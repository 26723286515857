import { useCallback, useEffect, useState } from "react";
import { EthersMulticall } from "@morpho-labs/ethers-multicall";
import { BigNumber, ethers } from "ethers";
import BN from "bignumber.js";
import { parseEther } from "ethers/lib/utils";
import { useAccount, useNetwork } from "wagmi";
import { uniswapV3FactoryAbi } from "./uniswapV3FactoryAbi";
import { uniswapV3PoolAbi } from "./uniswapV3PoolAbi";
import { NFT_TOKENS, TOKEN_SYMBOL, contracts } from "./tokens";
import { providers } from "./providers";
import { MAIN_CHAIN } from "./constants";

export const useUniV3PriceData = (tokenAddress, nftPrice) => {
  const [uniV3Price, setUniV3Price] = useState({
    amountTokensForOneEth: "0",
    amountTokensForOneEthBN: BigNumber.from(0),
    path: "0x0000",
    isWrappedEth: false,
    amountTokensForOneNft: "0",
    amountTokensForOneNftBN: BigNumber.from(0)
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchToogle, setRefetchToogle] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const refetch = () => {
    setRefetchToogle((prev) => !prev);
  };

  const reset = () => {
    setUniV3Price({
      amountTokensForOneEth: "0",
      amountTokensForOneEthBN: BigNumber.from(0),
      path: "0x0000",
      isWrappedEth: false,
      amountTokensForOneNft: "0",
      amountTokensForOneNftBN: BigNumber.from(0)
    });
    setIsError(false);
    setIsLoading(false);
    setRefetchToogle(false);
    setIsFetched(false);
  };

  const { chain } = useNetwork();
  const { address: walletAddress } = useAccount();

  const chainId = ethers.utils.hexValue(chain?.id ?? MAIN_CHAIN);

  const wrappedNative = contracts.wNative[chainId];
  const wrappedEth = contracts.wEth[chainId];

  const TOKEN = NFT_TOKENS[chainId].find(
    (token) => token.address === tokenAddress
  );

  const WETH = NFT_TOKENS[chainId].find(
    (token) => token.symbol === TOKEN_SYMBOL.WETH
  );

  const wEthDecimal = WETH?.decimals || Number(18);

  const fetchUniV3PriceData = useCallback(async () => {
    if (nftPrice === 0 || !tokenAddress) return;
    reset();
    setIsLoading(true);
    try {
      const swapPath =
        (wrappedNative === tokenAddress && WETH?.swapPath) || TOKEN?.swapPath;

      if (!swapPath || swapPath.length === 0) {
        throw new Error("swap path not found");
      }

      const provider = providers[chainId];
      const uniFactoryV3Address = contracts.uniV3Factory[chainId];

      const multicall = new EthersMulticall(provider);

      if (TOKEN?.isMultiHoopPath) {
        const [tokenA, feeTierAM, tokenM, feeTierMEth] = swapPath;
        const pathBytes = ethers.utils.solidityPack(
          ["address", "uint24", "address", "uint24", "address"],
          swapPath
        );

        const FactoryContract = multicall.wrap(
          new ethers.Contract(uniFactoryV3Address, uniswapV3FactoryAbi)
        );

        const [V3poolAddressAM, V3poolAddressMB] = await Promise.all([
          FactoryContract.getPool(tokenA, tokenM, feeTierAM),
          FactoryContract.getPool(tokenM, wrappedEth, feeTierMEth)
        ]);

        const poolContractAM = multicall.wrap(
          new ethers.Contract(V3poolAddressAM, uniswapV3PoolAbi)
        );
        const poolContractMB = multicall.wrap(
          new ethers.Contract(V3poolAddressMB, uniswapV3PoolAbi)
        );

        const [token0AM, slot0AM, token0MB, slot0MB] = await Promise.all([
          poolContractAM.token0(),
          poolContractAM.slot0(),
          poolContractMB.token0(),
          poolContractMB.slot0()
        ]);

        const isTokenAToken0AM = tokenA === token0AM;
        const isWETHToken0MB = wrappedEth === token0MB;

        const decimalsA =
          (wrappedNative !== tokenAddress && TOKEN?.decimals) || 18;
        const decimalsM =
          NFT_TOKENS[chainId].find((token) => token.address === tokenM)
            ?.decimals || 18;

        const precisionA = BN(10).pow(decimalsA);
        const precisionM = BN(10).pow(decimalsM);
        const precisionEth = BN(10).pow(wEthDecimal);

        const [precision0AM, precision1AM] = isTokenAToken0AM
          ? [precisionA, precisionM]
          : [precisionM, precisionA];
        const [precision0MB, precision1MB] = isWETHToken0MB
          ? [precisionEth, precisionM]
          : [precisionM, precisionEth];

        const { sqrtPriceX96: sqrtPriceX96AM } = slot0AM;
        const { sqrtPriceX96: sqrtPriceX96MB } = slot0MB;

        const priceAM = BN(sqrtPriceX96AM.toString()).div(BN(2).pow(96)).pow(2);
        const priceMB = BN(sqrtPriceX96MB.toString()).div(BN(2).pow(96)).pow(2);

        const buyOneOfToken0AM = priceAM
          .multipliedBy(precision0AM)
          .div(precision1AM);
        const amountTokenAForOneTokenM = isTokenAToken0AM
          ? buyOneOfToken0AM
          : BN(1).div(buyOneOfToken0AM);

        const buyOneOfToken0MB = priceMB
          .multipliedBy(precision0MB)
          .div(precision1MB);
        const amountTokenMForOneEth = isWETHToken0MB
          ? buyOneOfToken0MB
          : BN(1).div(buyOneOfToken0MB);

        const feeTiersAndSlippage =
          ((1000000 + Number(feeTierAM)) * (1000000 + Number(feeTierMEth))) /
            1000000 +
          1000; // add feeTierAM * feeTierMB and 0.1% slippage;

        const amountTokenAForOneEth = amountTokenMForOneEth
          .div(amountTokenAForOneTokenM)
          .multipliedBy(feeTiersAndSlippage)
          .div(1000000);

        const amountTokensForOneNft =
          amountTokenAForOneEth.multipliedBy(nftPrice);

        setUniV3Price({
          amountTokensForOneEth: amountTokenAForOneEth.toFixed(decimalsA),
          amountTokensForOneEthBN: BigNumber.from(
            `0x${amountTokenAForOneEth
              .multipliedBy(precisionA)
              .integerValue(BN.ROUND_CEIL)
              .toString(16)}`
          ),
          path: pathBytes,
          isWrappedEth: false,
          amountTokensForOneNft: amountTokensForOneNft.toFixed(decimalsA),
          amountTokensForOneNftBN: BigNumber.from(
            `0x${amountTokensForOneNft
              .multipliedBy(precisionA)
              .integerValue(BN.ROUND_CEIL)
              .toString(16)}`
          )
        });
        setIsFetched(true);
        return;
      }

      const [tokenInAddress, feeTier, wEthAddress] = swapPath;
      const pathBytes = ethers.utils.solidityPack(
        ["address", "uint24", "address"],
        swapPath
      );

      const FactoryContract = new ethers.Contract(
        uniFactoryV3Address,
        uniswapV3FactoryAbi,
        provider
      );

      const V3poolAddress = await FactoryContract.getPool(
        tokenInAddress,
        wEthAddress, // tokenOut ETH or WETH
        feeTier
      );

      const poolContract = multicall.wrap(
        new ethers.Contract(V3poolAddress, uniswapV3PoolAbi)
      );

      const [token0, slot0] = await Promise.all([
        poolContract.token0(),
        poolContract.slot0()
      ]);

      // const token0 = await poolContract.token0();
      const isToken0WETH = token0 === wEthAddress;

      // set default decimals to 18 if token address is wrapped native or not found in TOKENS
      const tokenDecimal =
        (wrappedNative !== tokenAddress && TOKEN?.decimals) || 18;
      const wEthPrecision = BN(10).pow(wEthDecimal);
      const tokenPrecision = BN(10).pow(tokenDecimal);

      const [precision0, precision1] = isToken0WETH
        ? [wEthPrecision, tokenPrecision]
        : [tokenPrecision, wEthPrecision];

      const { sqrtPriceX96 } = slot0;
      const priceBN = BN(sqrtPriceX96.toString()).div(BN(2).pow(96)).pow(2);

      const buyOneOfToken0 = priceBN.multipliedBy(precision0).div(precision1);

      const amountTokensForOneEth = (
        isToken0WETH ? buyOneOfToken0 : BN(1).div(buyOneOfToken0)
      )
        .multipliedBy(1000000 + Number(feeTier) + 1000)
        .div(1000000); // add fee tier and 0.1% slippage;

      const amountTokensForOneNft =
        amountTokensForOneEth.multipliedBy(nftPrice);

      setUniV3Price({
        amountTokensForOneEth: amountTokensForOneEth.toFixed(tokenDecimal),
        amountTokensForOneEthBN: BigNumber.from(
          `0x${amountTokensForOneEth
            .multipliedBy(tokenPrecision)
            .integerValue(BN.ROUND_CEIL)
            .toString(16)}`
        ),
        path: pathBytes,
        isWrappedEth: false,
        amountTokensForOneNft: amountTokensForOneNft.toFixed(tokenDecimal),
        amountTokensForOneNftBN: BigNumber.from(
          `0x${amountTokensForOneNft
            .multipliedBy(tokenPrecision)
            .integerValue(BN.ROUND_CEIL)
            .toString(16)}`
        )
      });
      setIsFetched(true);
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    chainId,
    nftPrice,
    tokenAddress,
    wEthDecimal,
    wrappedEth,
    wrappedNative,
    TOKEN
  ]);

  useEffect(() => {
    if (tokenAddress === wrappedEth) {
      const nftPriceStr = nftPrice.toString();
      reset();
      setUniV3Price({
        amountTokensForOneEth: "1",
        amountTokensForOneEthBN: parseEther("1"),
        path: "0x0000",
        isWrappedEth: true,
        amountTokensForOneNft: nftPriceStr,
        amountTokensForOneNftBN: BigNumber.from(
          (nftPrice * 10 ** wEthDecimal).toString()
        )
      });
      setIsFetched(true);
    } else if (chainId && walletAddress) {
      fetchUniV3PriceData();
    } else {
      reset();
    }
  }, [
    refetchToogle,
    chainId,
    tokenAddress,
    nftPrice,
    wrappedEth,
    wEthDecimal,
    fetchUniV3PriceData,
    walletAddress
  ]);

  return {
    uniV3Price,
    isError,
    isLoading,
    isFetched,
    refetch,
    reset
  };
};
