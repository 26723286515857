import { ethers } from "ethers";
import { layerZeroAbi } from "../Abi/layerZeroAbi";
import { MAIN_CHAIN } from "./constants";
import { contracts } from "./tokens";
import { getPublicClient } from "./web3Client";

const LAYERZERO_ENDPOINT = {
  "0x1": "0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675",
  "0xa4b1": "0x3c2269811836af69497E5F486A85D7316753cf62",
  "0xa": "0x3c2269811836af69497E5F486A85D7316753cf62",
  "0x89": "0x3c2269811836af69497E5F486A85D7316753cf62",
  "0x38": "0x3c2269811836af69497E5F486A85D7316753cf62",
  "0x5": "0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23",
  "0x66eed": "0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab",
  "0x61": "0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1",
  "0x144": "0x9b896c0e23220469C7AE69cb4BbAE391eAa4C8da",
  "0x118": "0x093D2CF57f764f09C3c2Ac58a42A2601B8C79281"
};

const LAYERZERO_CHAINID = {
  "0x1": 101,
  "0xa4b1": 110,
  "0xa": 111,
  "0x89": 109,
  "0x38": 102,
  "0x5": 10121,
  "0x66eed": 10143,
  "0x61": 10102,
  "0x144": 165,
  "0x118": 10165
};

export const estimateFees = async (chain, chainId, nftCount) => {
  try {
    const ORIGIN_CONTRACT = contracts.mintDeposit[chainId];

    const dstChainId = LAYERZERO_CHAINID[MAIN_CHAIN];

    const adapterParams = ethers.utils.solidityPack(
      ["uint16", "uint256"],
      [1, 2_000_000 + 600_000 * nftCount]
    );

    const types = ["bool", "uint256"];

    const payload = ethers.utils.solidityPack(types, [
      false,
      nftCount
      // whitelistAddresses[1],
      // proof.length,
      // ...proof
    ]);

    const response = await estimateFeesCall(
      chain,
      LAYERZERO_ENDPOINT[chainId],
      dstChainId, // the destination LayerZero chainId
      ORIGIN_CONTRACT, // your contract address that calls Endpoint.send()
      payload, //  payload
      adapterParams
    );

    return response;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

const estimateFeesCall = async (
  chain,
  tokenAddress,
  dstChainId,
  ORIGIN_CONTRACT,
  payload,
  adapterParams
) => {
  try {
    const response = await getPublicClient(chain).readContract({
      address: tokenAddress,
      abi: layerZeroAbi,
      functionName: "estimateFees",
      args: [dstChainId, ORIGIN_CONTRACT, payload, false, adapterParams]
    });
    return response;
  } catch (e) {
    console.log(e);
    return 0;
  }
};
