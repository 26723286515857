import React from "react";
import { BiXCircle } from "react-icons/bi";
import { NFT_TOKENS } from "../Utils/tokens";
import { ethers } from "ethers";

const CurrencySelectModal = ({ chainId, closeModal, onTokenSelect }) => {
  return (
    <div className='modal'>
      <div className='modal-div'>
        <div className='flex space-between font-large'>
          <div>Select a Token</div>
          <BiXCircle className='pointer' onClick={closeModal} size={32} />
        </div>
        <div>
          {NFT_TOKENS[ethers.utils.hexValue(chainId)].map((token) => (
            <div key={token.symbol}>
              {/* <img src={token.icon} alt={token.symbol} /> */}
              <div
                onClick={() => onTokenSelect(token)}
                className='token pointer'
              >
                {token.symbol.toUpperCase()}
              </div>
            </div>
          ))}
        </div>
        <div className='info'>Select a token to buy NFT</div>
      </div>
    </div>
  );
};

export default CurrencySelectModal;
