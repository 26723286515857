import Cats from "./Components/Cats";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  arbitrum,
  mainnet,
  polygon,
  optimism,
  bsc,
  bscTestnet,
  arbitrumGoerli,
  goerli,
  zkSync,
  zkSyncTestnet
} from "wagmi/chains";
import "./Styles/global.css";
import Mint from "./Components/Mint";
import WhitelistMessageBox from "./Components/WhitelistMessageBox";
import { SHOW_MINT, ENABLED_CHAIN_IDS } from "./Utils/constants";
import {toast, ToastContainer} from "react-toastify";
import React from "react";

const allChains = [
  arbitrum,
  mainnet,
  polygon,
  optimism,
  bsc,
  bscTestnet,
  arbitrumGoerli,
  goerli,
  zkSync,
  zkSyncTestnet
];

const enabledChains = ENABLED_CHAIN_IDS.map((chainId) =>
  allChains.find((chain) => `0x${chain.id.toString(16)}` === chainId)
);

const projectId = "35b31c8ffbfd99ac267e35ecdf60530a";

const { publicClient } = configureChains(enabledChains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains: enabledChains }),
  publicClient
});

const ethereumClient = new EthereumClient(wagmiConfig, enabledChains);

function App() {
  toast.info("Try our Rewards Calculator to estimate your Airdrop Rewards!", {
    position: "bottom-center",
    toastId: 24,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark"
  });
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <div className='page'>
          <Header />
          <div>
            <Mint />
            <Cats name='cats' />
            <Cats name='left-cats' />
          </div>
          <Footer />
          <ToastContainer />
        </div>
      </WagmiConfig>
      <Web3Modal
        themeMode='light'
        themeVariables={{
          "--w3m-overlay-background-color": "#00000088",
          "--w3m-accent-color": "#000",
          "--w3m-background-color": "#000"
        }}
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
}

export default App;
