import React, { useEffect, useState } from "react";
import { BiMinus, BiPlus, BiSolidChevronDown } from "react-icons/bi";
import CurrencySelectModal from "./CurrencySelectModal";
import { useAccount, useNetwork } from "wagmi";
import { ethers } from "ethers";
import { NFT_TOKENS, contracts } from "../Utils/tokens";
import {
  crossChainMint,
  getBalance,
  getMintedAmount,
  getTotalSupply,
  mintNFTs,
} from "../Utils/smartContractFunctions";
import { useUniV3PriceData } from "../Utils/useUniV3Price";
import { estimateFees } from "../Utils/layerZero";
import { getPublicClient } from "@wagmi/core";
import { ToastContainer, toast } from "react-toastify";
import {
  MAIN_CHAIN,
  MATIC_GAS_MULTIPLIER,
  PUBLIC_PRICE,
  STATIC_LAYER_ZERO_GAS,
  WHITELIST_PRICE
} from "../Utils/constants";
import { MerkleTree } from "merkletreejs";
import { whitelistAddressList } from "../Utils/address";
import "react-toastify/dist/ReactToastify.css";

const hashedWhitelistAddresses = whitelistAddressList.map((address) =>
  ethers.utils.keccak256(address)
);
const merkleTree = new MerkleTree(
  hashedWhitelistAddresses,
  ethers.utils.keccak256,
  {
    sortPairs: true
  }
);
const  Calculator = ({toggleStatus}) => {
  const [inputValue, changeInputValue] = useState(0);
  const [shareOfSupply, setShare] = useState(0);
  const rewardsPerNFT = 538;
  const [totalRewards, setTotalRewards] = useState(0);
  const onInputValueChange = ({target}) =>{
    const {value} = target;
    changeInputValue(value);
    setShare(value*100/5333);
    setTotalRewards(rewardsPerNFT*value);
  }
  return (<div className='mint-card calculator'>
    <div className='button-info close-button' onClick={toggleStatus}>×</div>
    <h1>Calculator</h1>
    <div className='calculator-content'>
      <form className='mint-item flex'><div>N° of ZDog NFTs:</div> <input type='number' placeholder='1' min={0} onChange={onInputValueChange}/></form>
      <div className='mint-item flex'><span>Your Share of Total Supply:</span>{shareOfSupply.toFixed(3)}%</div>
      <div className='mint-item flex'><span>Airdrop Rewards Target:</span>$4,101,170</div>
      <div className='mint-item flex'><span>Estimated Rewards per NFT Holder:</span>${rewardsPerNFT} </div>
      <div className='mint-item flex'><span>Your Estimated Share of Rewards:</span>${totalRewards.toFixed(1)}</div>
    </div>
    <div className='calculator-warning'>
      The core team is confident that our farming operations will be successful,
      but we cannot guarantee every farming operation will yield the projected rewards.
      Numbers are based on estimates from past airdrops and are indicative of the average wallet rewards distribution from the following airdrops: $OP, $ARB, $UNI, $1inch, $GTC, $DYDX, $ENS, $LOOKS, $BLUR, $ARKM
    </div>
  </div>);
}
const Mint = () => {
  const [numNfts, setNumNfts] = useState(1);
  const [nftsMinted, setNftsMinted] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const [userBal, setUserBal] = useState(0);
  const chainId = ethers.utils.hexValue(chain?.id ?? MAIN_CHAIN);
  const [userWhitelisted, setUserWhitelisted] = useState(false);
  const userPrice = userWhitelisted ? WHITELIST_PRICE : PUBLIC_PRICE;
  const [selectedToken, setSelectedToken] = useState(NFT_TOKENS[chainId][0]);
  const { uniV3Price } = useUniV3PriceData(selectedToken?.address, userPrice);
  const [transFees, setTransFees] = useState(0);
  const [loading, setLoading] = useState(false);
  const isMainChain = chainId === MAIN_CHAIN;
  const [showCalculator,setShowCalculator] = useState(false);
  const onPlusMinusClick = (action) => {
    if (action === "plus") {
      if (numNfts < 10 && numNfts + nftsMinted < 30) setNumNfts(numNfts + 1);
    } else {
      if (numNfts > 1) setNumNfts(numNfts - 1);
    }
  };

  const onTokenSelect = (token) => {
    setShowModal(false);
    setSelectedToken(token);
  };

  const getUserBalance = async () => {
    if (
      selectedToken.symbol.toUpperCase() ===
      chain?.nativeCurrency?.symbol?.toUpperCase()
    ) {
      const balance = await getPublicClient().getBalance({
        address: address
      });
      setUserBal(Number(balance.toString()) / 10 ** 18);
    } else {
      const response = await getBalance(
        chainId,
        selectedToken.address,
        address
      );
      setUserBal(Number(response ?? 0) / 10 ** selectedToken.decimals);
    }
  };

  const estimateTxFee = async () => {
    const response = await estimateFees(chain, chainId, numNfts);

    let multiplier = 1.075;

    if (chain?.nativeCurrency?.symbol?.toUpperCase() === "MATIC") {
      multiplier = MATIC_GAS_MULTIPLIER;
    }

    setTransFees(
      response
        ? (response[0].toString() * multiplier) /
            10 ** chain?.nativeCurrency?.decimals
        : STATIC_LAYER_ZERO_GAS[chainId]
    );
  };

  const onMintClick = async () => {
    let response = null;
    setLoading(true);
    const txValue = numNfts * pricePerNft;
    if(userBal < txValue){
        toast.error("Not enough balance for NFTs", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          toastId: 99,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark"
        });
      setLoading(false);
        return;
    }
      if (isMainChain) {
        response = await mintNFTs(
          chain,
          numNfts,
          contracts.nftContract[chainId]
        );
      } else {
        const txValuePlusGas = txValue + transFees;
        if(userBal < txValuePlusGas){
          toast.warning("Not enough balance for gas!", {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            toastId: 100,
            progress: undefined,
            theme: "dark"
          });
          setLoading(false);
          return;
        }
        response = await crossChainMint(
          chain,
          numNfts,
          contracts.mintDeposit[chainId],
          transFees,
          numNfts *
            uniV3Price.amountTokensForOneNft *
            10 ** selectedToken.decimals,
          selectedToken,
          false
        );
      }
    setLoading(false);
    getUserBalance();
    getTotalSupplyFn();
    if (response) {
      toast.success("NFTs Minted!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark"
      });
      setNftsMinted(nftsMinted + numNfts);
      setNumNfts(1);
    } else {
      toast.error("Mint Failed. Try Again!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark"
      });
    }
  };

  const getMintedAmountFn = async () => {
    const response = await getMintedAmount(address);
    const minted = Number(response?.toString());
    if (!isNaN(minted)) {
      setNftsMinted(minted);
    } else {
      setNftsMinted(0);
    }
  };

  const getTotalSupplyFn = async () => {
    const response = await getTotalSupply();
    const totalSupply = Number(response?.toString());
    if (!isNaN(totalSupply)) {
      setTotalSupply(totalSupply);
    } else {
      setTotalSupply(0);
    }
  };

  const pricePerNft = ["ETH", "WETH"].includes(
    selectedToken.symbol.toUpperCase()
  )
    ? userPrice
    : Number(uniV3Price.amountTokensForOneNft) !== 0
    ? Number(uniV3Price.amountTokensForOneNft).toFixed(2)
    : userPrice;

  useEffect(() => {
    getUserBalance();
  }, [selectedToken, chainId]);

  useEffect(() => {
    estimateTxFee();
  }, [numNfts, chainId]);

  useEffect(() => {
    setSelectedToken(NFT_TOKENS[chainId][0]);
    getMintedAmountFn();
  }, [chainId]);

  useEffect(() => {
    const hashedAddress = ethers.utils.keccak256(address|| ethers.constants.HashZero);
    const proof = merkleTree.getHexProof(hashedAddress);
    if (proof?.length > 0) {
      setUserWhitelisted(false);
    } else {
      setUserWhitelisted(false);
    }
    getMintedAmountFn();
  }, [address]);

  useEffect(() => {
    getTotalSupplyFn();
  }, [address, chainId]);

  const balanceReq = (
    numNfts * Number(uniV3Price?.amountTokensForOneNft)
  ).toFixed(2);

  const maxNFTs = 4666;

  const isBalanceEnough = userBal >= balanceReq;
  const isMaxNFTsPerWalletReached = nftsMinted + numNfts > 30;
  const isSoldOut = totalSupply >= maxNFTs;
  const isMaxNFTsReached = (totalSupply + numNfts) >= maxNFTs;

  const isBtnDisabled =
    !isBalanceEnough
    || isMaxNFTsPerWalletReached
    || isSoldOut
    || isMaxNFTsReached;

  const buttonDisabledText =
    !isBalanceEnough ? "Insufficient Balance"
    : isMaxNFTsPerWalletReached ? "Max NFTs per User Reached"
    : isSoldOut ? "Sold Out"
    : isMaxNFTsReached ? "Max NFTs Reached"
    : "";
  const toggleCalculatorState = () => {
    setShowCalculator((prevState)=>!prevState);
  }
  const MintCard = () => (<>
    <div className='mint-card'>
      <div className='button-info open-calculator' onClick={toggleCalculatorState}><img className='icon' alt='calculator icon' src='/calculator.png'/>Rewards Calculator</div>
      <h1 className='card-header'>Mint NFT</h1>
      <div>
        <div className='flex space-between'>
          <div>NFTs to mint</div>
          <div className='flex center font-large'>
            <div
                onClick={() => onPlusMinusClick("minus")}
                className='mr-12 plus-minus-btn'
            >
              <BiMinus size={16} />
            </div>
            <div className='mr-12'>{numNfts}</div>
            <div
                onClick={() => onPlusMinusClick("plus")}
                className='plus-minus-btn'
            >
              <BiPlus size={16} />
            </div>
          </div>
        </div>
        <hr />
        <div className='flex space-between'>
          <div className='flex mxw-50'>
            <div className='mr-4'>Price per NFT:</div>
            <div>
              <b>
                {pricePerNft} {selectedToken.symbol.toUpperCase() ?? "ETH"}
              </b>
            </div>
          </div>
          <div className='flex mxw-50 flex-end'>
            <div>Your Balance:</div>
            <div className='ml-4'>
              <b>
                {userBal ? userBal.toFixed(4) : "0.0000"}
                {selectedToken.symbol.toUpperCase()}
              </b>
            </div>
          </div>
        </div>
        <div className='flex space-between font-large'>
          <div>
            {!isNaN(Number(numNfts * pricePerNft))
                ? Number(numNfts * pricePerNft).toFixed(4)
                : "0.0000"}
          </div>
          <div
              className='pointer'
              onClick={() => {
                setShowModal(true);
              }}
          >
            {selectedToken.symbol.toUpperCase()}
            <BiSolidChevronDown size={20} />
          </div>
        </div>
        <div className='flex'>
          {transFees > 0 &&
              `+ ${transFees.toFixed(4)} ${
                  chain?.nativeCurrency?.symbol
              } for transaction costs`}
        </div>
        <hr />
        <div className='flex center mt-32'>
          {nftsMinted ? `You have already minted ${nftsMinted}/30 NFTs` : ""}
        </div>
        <button
            onClick={onMintClick}
            disabled={isBtnDisabled}
            className='mint-btn'
        >
          {isBtnDisabled
              ? buttonDisabledText
              : loading
                  ? "Minting..."
                  : "Mint"
          }
        </button>
      </div>
    </div>
    {showModal && (
        <CurrencySelectModal
            chainId={chainId}
            onTokenSelect={onTokenSelect}
            closeModal={() => setShowModal(false)}
        />
    )}
  </>);
  return (<div className='mint'>
        {!showCalculator && <MintCard/>}
        {showCalculator && <Calculator toggleStatus={toggleCalculatorState}/>}
        <ToastContainer />
    </div>
  );
};

export default Mint;
