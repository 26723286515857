import React from "react";
import ZKittyLogo from "./Logos/ZKittyLogo";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";

const Header = () => {
  return (
    <div className='flex header'>
      <div
        className='flex center'
        onClick={() => window.open("https://zkitty.xyz/", "_blank")}
      >
        <ZKittyLogo />
        <div className='name'>ZKitty</div>
      </div>
      <div className='flex'>
        <div className='mr-12'>
          <Web3Button className='web3-button' />
        </div>
        <div>
          <Web3NetworkSwitch />
        </div>
      </div>
    </div>
  );
};

export default Header;
